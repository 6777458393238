/* eslint-disable no-prototype-builtins */
/* eslint-disable unicorn/prefer-number-properties */
/* eslint-disable ts/ban-ts-comment */

// @ts-nocheck - This file is generated and we don't want to fix all the issues in it, and even notice them.
// ============================================================================
// ╔══════════════════════════════════════════════════════════════════════════╗
// ║                                                                          ║
// ║              GENERATED BY APP-CONFIG-GENERATOR                           ║
// ║                                                                          ║
// ║                       DO NOT MODIFY                                      ║
// ║                                                                          ║
// ╚══════════════════════════════════════════════════════════════════════════╝
// ============================================================================

// Configuration directories were used: 
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/.cfg-base'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/urbaser/.base'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/urbaser/prod'

// Configuration files were processed: 
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/.cfg-base/app-config.shared.web-app.config.json'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/.cfg-base/app-config.shared.ai.config.json'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/.cfg-base/app-config.shared.adminapp.config.json'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/.cfg-base/app-config.localization.json'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/.cfg-base/app-config.json'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/.cfg-base/app-config.bundleNsStringsDescriptions.json'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/.cfg-base/app-config.branding.json'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/urbaser/.base/app-config.json'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/urbaser/.base/app-config.ai.config.json'
// '/home/vsts/work/1/s/InphizCore.ReactNative/packages/app-config/urbaser/prod/app-config.json'


// To parse this data:
//
//   import { Convert, AppConfig } from "./file";
//
//   const appConfig = Convert.toAppConfig(json);
//
// These functions will throw an error if the JSON doesn't
// match the expected interface, even if the JSON is valid.

export interface AppConfig {
    displayName: string;
    name:        string;
    properties:  Properties;
    style:       Style;
}

export interface Properties {
    AlwaysUseAuthorizationApiKey:    boolean;
    AppCenterAppId:                  AppCenterAppID;
    AuthorizationApiKey:             string;
    AzureServiceBusConnectionString: string;
    BlobStorageUrls:                 BlobStorageURL[];
    BuildInfo:                       BuildInfo;
    ServiceBaseUrl:                  string;
    ServiceBaseUrlApiEndpointPath:   string;
    ServiceBaseUrlApiVersion:        string;
    ServiceBaseUrlDefaultApiVersion: string;
    administratorApp:                AdministratorApp;
    aiAssistant:                     AIAssistant;
    auth_azureb2c:                   AuthAzureb2C;
    auth_msal:                       AuthMsal;
    auth_type:                       AuthType;
    webApp:                          WebApp;
}

export interface AppCenterAppID {
    android: string;
    ios:     string;
}

export interface BlobStorageURL {
    IgnoreAuthHeaders: boolean;
    Url:               string;
}

export interface BuildInfo {
    BuildVersionInfo: string;
    Flavor:           string;
}

export interface AdministratorApp {
    package: string;
    scheme:  string;
}

export interface AIAssistant {
    assistantApiKey: string;
    assistantID:     string;
    url:             string;
}

export interface AuthAzureb2C {
    AndroidBrowserTabActivity:        AuthAzureb2CAndroidBrowserTabActivity;
    AuthenticationExtraScopes:        any[];
    AuthenticationUseEmbeddedWebView: boolean;
    AzureADB2CHostname:               string;
    ClientID:                         string;
    IOSKeyChainGroup:                 string;
    LoginHintType:                    string;
    PolicyEditProfile:                string;
    PolicyResetPassword:              string;
    PolicySignUpSignIn:               string;
    Scopes:                           string[];
    Tenant:                           string;
}

export interface AuthAzureb2CAndroidBrowserTabActivity {
    host:   string;
    scheme: string;
}

export interface AuthMsal {
    AndroidBrowserTabActivity:        AuthMsalAndroidBrowserTabActivity;
    AuthenticationAppClientId:        string;
    AuthenticationDomain:             string;
    AuthenticationExtraScopes:        any[];
    AuthenticationRedirectUrl:        string;
    AuthenticationTenantId:           string;
    AuthenticationUseEmbeddedWebView: boolean;
    IOSKeyChainGroup:                 string;
    Scopes:                           any[];
}

export interface AuthMsalAndroidBrowserTabActivity {
    host:   string;
    path:   string;
    scheme: string;
}

export enum AuthType {
    AzureB2C = "AzureB2C",
    Msal = "MSAL",
    None = "none",
}

export interface WebApp {
    postLogoutRedirectUri: string;
    redirectUri:           string;
}

export interface Style {
    buttons:         Buttons;
    colors:          Colors;
    elements:        Elements;
    fWelcomeScreens: FWelcomeScreens;
    features:        Features;
    headerBar:       HeaderBar;
    tabBar:          TabBar;
}

export interface Buttons {
    error:     Error;
    primary:   Error;
    secondary: Error;
    success:   Error;
    warning:   Error;
}

export interface Error {
    disabled: Disabled;
    normal:   Disabled;
}

export interface Disabled {
    borderColor:     string;
    borderThickness: number;
    color:           string;
    textColor:       string;
}

export interface Colors {
    active:                           string;
    aggregated:                       string;
    "color-indicator-active":         string;
    "color-indicator-inactive":       string;
    "color-text-secondary-temporary": string;
    danger:                           string;
    danger25:                         string;
    darkGrey50:                       string;
    expired:                          string;
    expiringSoon:                     string;
    grey:                             string;
    grey20:                           string;
    greyInfoBG:                       string;
    greyInfoBG25:                     string;
    info:                             string;
    info25:                           string;
    lightGrey:                        string;
    lightGrey2:                       string;
    lightGrey3:                       string;
    lightGrey4:                       string;
    lightGrey8:                       string;
    missing:                          string;
    primary:                          string;
    pureBlack:                        string;
    pureWhite:                        string;
    secondary:                        string;
    signal:                           string;
    signal25:                         string;
    success:                          string;
    success25:                        string;
    textBlack:                        string;
    transparentBlack:                 string;
    unlocked:                         string;
    warning:                          string;
    warning25:                        string;
    white:                            string;
}

export interface Elements {
    accent:             Accent;
    contentLoginScreen: ContentLoginScreen;
}

export interface Accent {
    color:     string;
    textColor: string;
}

export interface ContentLoginScreen {
    color:              string;
    textColorPrimary:   string;
    textColorSecondary: string;
}

export interface FWelcomeScreens {
    "color-action-primary":       string;
    "color-background-primary":   string;
    "color-background-secondary": string;
    "color-foreground-primary":   string;
    "color-foreground-secondary": string;
    "color-indicator-active":     string;
    "color-indicator-inactive":   string;
    "color-text-primary":         string;
    "color-text-secondary":       string;
}

export interface Features {
    initializationScreen: Screen;
    loginHelpScreen:      Screen;
    loginScreen:          Screen;
}

export interface Screen {
    backgroundImage: string;
}

export interface HeaderBar {
    backgroundColor:     string;
    buttonColor:         string;
    buttonInactiveColor: string;
    loginScreen?:        HeaderBar;
    textColor:           string;
}

export interface TabBar {
    activeTintColor:       string;
    activeTintIconColor:   string;
    inactiveTintColor:     string;
    inactiveTintIconColor: string;
}

// Converts JSON strings to/from your types
// and asserts the results of JSON.parse at runtime
export class Convert {
    public static toAppConfig(json: string): AppConfig {
        return cast(JSON.parse(json), r("AppConfig"));
    }

    public static appConfigToJson(value: AppConfig): string {
        return JSON.stringify(uncast(value, r("AppConfig")), null, 2);
    }
}

function invalidValue(typ: any, val: any, key: any, parent: any = ''): never {
    const prettyTyp = prettyTypeName(typ);
    const parentText = parent ? ` on ${parent}` : '';
    const keyText = key ? ` for key "${key}"` : '';
    throw Error(`Invalid value${keyText}${parentText}. Expected ${prettyTyp} but got ${JSON.stringify(val)}`);
}

function prettyTypeName(typ: any): string {
    if (Array.isArray(typ)) {
        if (typ.length === 2 && typ[0] === undefined) {
            return `an optional ${prettyTypeName(typ[1])}`;
        } else {
            return `one of [${typ.map(a => { return prettyTypeName(a); }).join(", ")}]`;
        }
    } else if (typeof typ === "object" && typ.literal !== undefined) {
        return typ.literal;
    } else {
        return typeof typ;
    }
}

function jsonToJSProps(typ: any): any {
    if (typ.jsonToJS === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.json] = { key: p.js, typ: p.typ });
        typ.jsonToJS = map;
    }
    return typ.jsonToJS;
}

function jsToJSONProps(typ: any): any {
    if (typ.jsToJSON === undefined) {
        const map: any = {};
        typ.props.forEach((p: any) => map[p.js] = { key: p.json, typ: p.typ });
        typ.jsToJSON = map;
    }
    return typ.jsToJSON;
}

function transform(val: any, typ: any, getProps: any, key: any = '', parent: any = ''): any {
    function transformPrimitive(typ: string, val: any): any {
        if (typeof typ === typeof val) return val;
        return invalidValue(typ, val, key, parent);
    }

    function transformUnion(typs: any[], val: any): any {
        // val must validate against one typ in typs
        const l = typs.length;
        for (let i = 0; i < l; i++) {
            const typ = typs[i];
            try {
                return transform(val, typ, getProps);
            } catch (_) {}
        }
        return invalidValue(typs, val, key, parent);
    }

    function transformEnum(cases: string[], val: any): any {
        if (cases.indexOf(val) !== -1) return val;
        return invalidValue(cases.map(a => { return l(a); }), val, key, parent);
    }

    function transformArray(typ: any, val: any): any {
        // val must be an array with no invalid elements
        if (!Array.isArray(val)) return invalidValue(l("array"), val, key, parent);
        return val.map(el => transform(el, typ, getProps));
    }

    function transformDate(val: any): any {
        if (val === null) {
            return null;
        }
        const d = new Date(val);
        if (isNaN(d.valueOf())) {
            return invalidValue(l("Date"), val, key, parent);
        }
        return d;
    }

    function transformObject(props: { [k: string]: any }, additional: any, val: any): any {
        if (val === null || typeof val !== "object" || Array.isArray(val)) {
            return invalidValue(l(ref || "object"), val, key, parent);
        }
        const result: any = {};
        Object.getOwnPropertyNames(props).forEach(key => {
            const prop = props[key];
            const v = Object.prototype.hasOwnProperty.call(val, key) ? val[key] : undefined;
            result[prop.key] = transform(v, prop.typ, getProps, key, ref);
        });
        Object.getOwnPropertyNames(val).forEach(key => {
            if (!Object.prototype.hasOwnProperty.call(props, key)) {
                result[key] = transform(val[key], additional, getProps, key, ref);
            }
        });
        return result;
    }

    if (typ === "any") return val;
    if (typ === null) {
        if (val === null) return val;
        return invalidValue(typ, val, key, parent);
    }
    if (typ === false) return invalidValue(typ, val, key, parent);
    let ref: any = undefined;
    while (typeof typ === "object" && typ.ref !== undefined) {
        ref = typ.ref;
        typ = typeMap[typ.ref];
    }
    if (Array.isArray(typ)) return transformEnum(typ, val);
    if (typeof typ === "object") {
        return typ.hasOwnProperty("unionMembers") ? transformUnion(typ.unionMembers, val)
            : typ.hasOwnProperty("arrayItems")    ? transformArray(typ.arrayItems, val)
            : typ.hasOwnProperty("props")         ? transformObject(getProps(typ), typ.additional, val)
            : invalidValue(typ, val, key, parent);
    }
    // Numbers can be parsed by Date but shouldn't be.
    if (typ === Date && typeof val !== "number") return transformDate(val);
    return transformPrimitive(typ, val);
}

function cast<T>(val: any, typ: any): T {
    return transform(val, typ, jsonToJSProps);
}

function uncast<T>(val: T, typ: any): any {
    return transform(val, typ, jsToJSONProps);
}

function l(typ: any) {
    return { literal: typ };
}

function a(typ: any) {
    return { arrayItems: typ };
}

function u(...typs: any[]) {
    return { unionMembers: typs };
}

function o(props: any[], additional: any) {
    return { props, additional };
}

function m(additional: any) {
    return { props: [], additional };
}

function r(name: string) {
    return { ref: name };
}

const typeMap: any = {
    "AppConfig": o([
        { json: "displayName", js: "displayName", typ: "" },
        { json: "name", js: "name", typ: "" },
        { json: "properties", js: "properties", typ: r("Properties") },
        { json: "style", js: "style", typ: r("Style") },
    ], false),
    "Properties": o([
        { json: "AlwaysUseAuthorizationApiKey", js: "AlwaysUseAuthorizationApiKey", typ: true },
        { json: "AppCenterAppId", js: "AppCenterAppId", typ: r("AppCenterAppID") },
        { json: "AuthorizationApiKey", js: "AuthorizationApiKey", typ: "" },
        { json: "AzureServiceBusConnectionString", js: "AzureServiceBusConnectionString", typ: "" },
        { json: "BlobStorageUrls", js: "BlobStorageUrls", typ: a(r("BlobStorageURL")) },
        { json: "BuildInfo", js: "BuildInfo", typ: r("BuildInfo") },
        { json: "ServiceBaseUrl", js: "ServiceBaseUrl", typ: "" },
        { json: "ServiceBaseUrlApiEndpointPath", js: "ServiceBaseUrlApiEndpointPath", typ: "" },
        { json: "ServiceBaseUrlApiVersion", js: "ServiceBaseUrlApiVersion", typ: "" },
        { json: "ServiceBaseUrlDefaultApiVersion", js: "ServiceBaseUrlDefaultApiVersion", typ: "" },
        { json: "administratorApp", js: "administratorApp", typ: r("AdministratorApp") },
        { json: "aiAssistant", js: "aiAssistant", typ: r("AIAssistant") },
        { json: "auth_azureb2c", js: "auth_azureb2c", typ: r("AuthAzureb2C") },
        { json: "auth_msal", js: "auth_msal", typ: r("AuthMsal") },
        { json: "auth_type", js: "auth_type", typ: r("AuthType") },
        { json: "webApp", js: "webApp", typ: r("WebApp") },
    ], false),
    "AppCenterAppID": o([
        { json: "android", js: "android", typ: "" },
        { json: "ios", js: "ios", typ: "" },
    ], false),
    "BlobStorageURL": o([
        { json: "IgnoreAuthHeaders", js: "IgnoreAuthHeaders", typ: true },
        { json: "Url", js: "Url", typ: "" },
    ], false),
    "BuildInfo": o([
        { json: "BuildVersionInfo", js: "BuildVersionInfo", typ: "" },
        { json: "Flavor", js: "Flavor", typ: "" },
    ], false),
    "AdministratorApp": o([
        { json: "package", js: "package", typ: "" },
        { json: "scheme", js: "scheme", typ: "" },
    ], false),
    "AIAssistant": o([
        { json: "assistantApiKey", js: "assistantApiKey", typ: "" },
        { json: "assistantID", js: "assistantID", typ: "" },
        { json: "url", js: "url", typ: "" },
    ], false),
    "AuthAzureb2C": o([
        { json: "AndroidBrowserTabActivity", js: "AndroidBrowserTabActivity", typ: r("AuthAzureb2CAndroidBrowserTabActivity") },
        { json: "AuthenticationExtraScopes", js: "AuthenticationExtraScopes", typ: a("any") },
        { json: "AuthenticationUseEmbeddedWebView", js: "AuthenticationUseEmbeddedWebView", typ: true },
        { json: "AzureADB2CHostname", js: "AzureADB2CHostname", typ: "" },
        { json: "ClientID", js: "ClientID", typ: "" },
        { json: "IOSKeyChainGroup", js: "IOSKeyChainGroup", typ: "" },
        { json: "LoginHintType", js: "LoginHintType", typ: "" },
        { json: "PolicyEditProfile", js: "PolicyEditProfile", typ: "" },
        { json: "PolicyResetPassword", js: "PolicyResetPassword", typ: "" },
        { json: "PolicySignUpSignIn", js: "PolicySignUpSignIn", typ: "" },
        { json: "Scopes", js: "Scopes", typ: a("") },
        { json: "Tenant", js: "Tenant", typ: "" },
    ], false),
    "AuthAzureb2CAndroidBrowserTabActivity": o([
        { json: "host", js: "host", typ: "" },
        { json: "scheme", js: "scheme", typ: "" },
    ], false),
    "AuthMsal": o([
        { json: "AndroidBrowserTabActivity", js: "AndroidBrowserTabActivity", typ: r("AuthMsalAndroidBrowserTabActivity") },
        { json: "AuthenticationAppClientId", js: "AuthenticationAppClientId", typ: "" },
        { json: "AuthenticationDomain", js: "AuthenticationDomain", typ: "" },
        { json: "AuthenticationExtraScopes", js: "AuthenticationExtraScopes", typ: a("any") },
        { json: "AuthenticationRedirectUrl", js: "AuthenticationRedirectUrl", typ: "" },
        { json: "AuthenticationTenantId", js: "AuthenticationTenantId", typ: "" },
        { json: "AuthenticationUseEmbeddedWebView", js: "AuthenticationUseEmbeddedWebView", typ: true },
        { json: "IOSKeyChainGroup", js: "IOSKeyChainGroup", typ: "" },
        { json: "Scopes", js: "Scopes", typ: a("any") },
    ], false),
    "AuthMsalAndroidBrowserTabActivity": o([
        { json: "host", js: "host", typ: "" },
        { json: "path", js: "path", typ: "" },
        { json: "scheme", js: "scheme", typ: "" },
    ], false),
    "WebApp": o([
        { json: "postLogoutRedirectUri", js: "postLogoutRedirectUri", typ: "" },
        { json: "redirectUri", js: "redirectUri", typ: "" },
    ], false),
    "Style": o([
        { json: "buttons", js: "buttons", typ: r("Buttons") },
        { json: "colors", js: "colors", typ: r("Colors") },
        { json: "elements", js: "elements", typ: r("Elements") },
        { json: "fWelcomeScreens", js: "fWelcomeScreens", typ: r("FWelcomeScreens") },
        { json: "features", js: "features", typ: r("Features") },
        { json: "headerBar", js: "headerBar", typ: r("HeaderBar") },
        { json: "tabBar", js: "tabBar", typ: r("TabBar") },
    ], false),
    "Buttons": o([
        { json: "error", js: "error", typ: r("Error") },
        { json: "primary", js: "primary", typ: r("Error") },
        { json: "secondary", js: "secondary", typ: r("Error") },
        { json: "success", js: "success", typ: r("Error") },
        { json: "warning", js: "warning", typ: r("Error") },
    ], false),
    "Error": o([
        { json: "disabled", js: "disabled", typ: r("Disabled") },
        { json: "normal", js: "normal", typ: r("Disabled") },
    ], false),
    "Disabled": o([
        { json: "borderColor", js: "borderColor", typ: "" },
        { json: "borderThickness", js: "borderThickness", typ: 0 },
        { json: "color", js: "color", typ: "" },
        { json: "textColor", js: "textColor", typ: "" },
    ], false),
    "Colors": o([
        { json: "active", js: "active", typ: "" },
        { json: "aggregated", js: "aggregated", typ: "" },
        { json: "color-indicator-active", js: "color-indicator-active", typ: "" },
        { json: "color-indicator-inactive", js: "color-indicator-inactive", typ: "" },
        { json: "color-text-secondary-temporary", js: "color-text-secondary-temporary", typ: "" },
        { json: "danger", js: "danger", typ: "" },
        { json: "danger25", js: "danger25", typ: "" },
        { json: "darkGrey50", js: "darkGrey50", typ: "" },
        { json: "expired", js: "expired", typ: "" },
        { json: "expiringSoon", js: "expiringSoon", typ: "" },
        { json: "grey", js: "grey", typ: "" },
        { json: "grey20", js: "grey20", typ: "" },
        { json: "greyInfoBG", js: "greyInfoBG", typ: "" },
        { json: "greyInfoBG25", js: "greyInfoBG25", typ: "" },
        { json: "info", js: "info", typ: "" },
        { json: "info25", js: "info25", typ: "" },
        { json: "lightGrey", js: "lightGrey", typ: "" },
        { json: "lightGrey2", js: "lightGrey2", typ: "" },
        { json: "lightGrey3", js: "lightGrey3", typ: "" },
        { json: "lightGrey4", js: "lightGrey4", typ: "" },
        { json: "lightGrey8", js: "lightGrey8", typ: "" },
        { json: "missing", js: "missing", typ: "" },
        { json: "primary", js: "primary", typ: "" },
        { json: "pureBlack", js: "pureBlack", typ: "" },
        { json: "pureWhite", js: "pureWhite", typ: "" },
        { json: "secondary", js: "secondary", typ: "" },
        { json: "signal", js: "signal", typ: "" },
        { json: "signal25", js: "signal25", typ: "" },
        { json: "success", js: "success", typ: "" },
        { json: "success25", js: "success25", typ: "" },
        { json: "textBlack", js: "textBlack", typ: "" },
        { json: "transparentBlack", js: "transparentBlack", typ: "" },
        { json: "unlocked", js: "unlocked", typ: "" },
        { json: "warning", js: "warning", typ: "" },
        { json: "warning25", js: "warning25", typ: "" },
        { json: "white", js: "white", typ: "" },
    ], false),
    "Elements": o([
        { json: "accent", js: "accent", typ: r("Accent") },
        { json: "contentLoginScreen", js: "contentLoginScreen", typ: r("ContentLoginScreen") },
    ], false),
    "Accent": o([
        { json: "color", js: "color", typ: "" },
        { json: "textColor", js: "textColor", typ: "" },
    ], false),
    "ContentLoginScreen": o([
        { json: "color", js: "color", typ: "" },
        { json: "textColorPrimary", js: "textColorPrimary", typ: "" },
        { json: "textColorSecondary", js: "textColorSecondary", typ: "" },
    ], false),
    "FWelcomeScreens": o([
        { json: "color-action-primary", js: "color-action-primary", typ: "" },
        { json: "color-background-primary", js: "color-background-primary", typ: "" },
        { json: "color-background-secondary", js: "color-background-secondary", typ: "" },
        { json: "color-foreground-primary", js: "color-foreground-primary", typ: "" },
        { json: "color-foreground-secondary", js: "color-foreground-secondary", typ: "" },
        { json: "color-indicator-active", js: "color-indicator-active", typ: "" },
        { json: "color-indicator-inactive", js: "color-indicator-inactive", typ: "" },
        { json: "color-text-primary", js: "color-text-primary", typ: "" },
        { json: "color-text-secondary", js: "color-text-secondary", typ: "" },
    ], false),
    "Features": o([
        { json: "initializationScreen", js: "initializationScreen", typ: r("Screen") },
        { json: "loginHelpScreen", js: "loginHelpScreen", typ: r("Screen") },
        { json: "loginScreen", js: "loginScreen", typ: r("Screen") },
    ], false),
    "Screen": o([
        { json: "backgroundImage", js: "backgroundImage", typ: "" },
    ], false),
    "HeaderBar": o([
        { json: "backgroundColor", js: "backgroundColor", typ: "" },
        { json: "buttonColor", js: "buttonColor", typ: "" },
        { json: "buttonInactiveColor", js: "buttonInactiveColor", typ: "" },
        { json: "loginScreen", js: "loginScreen", typ: u(undefined, r("HeaderBar")) },
        { json: "textColor", js: "textColor", typ: "" },
    ], false),
    "TabBar": o([
        { json: "activeTintColor", js: "activeTintColor", typ: "" },
        { json: "activeTintIconColor", js: "activeTintIconColor", typ: "" },
        { json: "inactiveTintColor", js: "inactiveTintColor", typ: "" },
        { json: "inactiveTintIconColor", js: "inactiveTintIconColor", typ: "" },
    ], false),
    "AuthType": [
        "AzureB2C",
        "MSAL",
        "none",
    ],
};
export const appConfig: AppConfig = cast({
  "properties": {
    "webApp": {
      "redirectUri": "/",
      "postLogoutRedirectUri": "/"
    },
    "aiAssistant": {
      "url": "https://api.djupbla.ai/api/",
      "assistantID": "e2e09d47-598f-4f80-bc5d-b756abb3d3d0",
      "assistantApiKey": "4e417dd5-a86b-431f-9965-4e7c69ea697e"
    },
    "administratorApp": {
      "package": "se.inphiz.internaltools.app",
      "scheme": "e7d660db-9a6f-45cb-aa78-f3d1c999da5a"
    },
    "ServiceBaseUrl": "https://api-urbaser-prod.azurewebsites.net",
    "ServiceBaseUrlApiEndpointPath": "api",
    "ServiceBaseUrlDefaultApiVersion": "1.0",
    "AuthorizationApiKey": "d960b94b-91f4-48f9-bffc-06e8262a2f81",
    "AlwaysUseAuthorizationApiKey": false,
    "AzureServiceBusConnectionString": "Endpoint=sb://sb-urbaser-prod.servicebus.windows.net/;SharedAccessKeyName=RootManageSharedAccessKey;SharedAccessKey=7xtfvOh21+clW41MbFJFSSj1xQ47lrrZahq5yAL+s/o=",
    "AppCenterAppId": {
      "ios": "27fc2988-9309-4d6b-b347-6d9ef35ee5f3",
      "android": "c39e7a53-2d91-4ed5-8967-d74655b2b3b0"
    },
    "BuildInfo": {
      "BuildVersionInfo": "1.0-build-24056.240e673",
      "Flavor": "urbaser/prod"
    },
    "BlobStorageUrls": [
      {
        "Url": "",
        "IgnoreAuthHeaders": true
      }
    ],
    "auth_type": "AzureB2C",
    "auth_azureb2c": {
      "Tenant": "inphizurbaserb2c.onmicrosoft.com",
      "AzureADB2CHostname": "inphizurbaserb2c.b2clogin.com",
      "ClientID": "6f1bf067-d09a-4e31-9590-6818d812919e",
      "PolicySignUpSignIn": "B2C_1_AD_login",
      "PolicyEditProfile": "b2c_1_edit_profile",
      "PolicyResetPassword": "b2c_1_reset",
      "IOSKeyChainGroup": "com.microsoft.adalcache",
      "AuthenticationUseEmbeddedWebView": true,
      "Scopes": [
        "https://inphizurbaserb2c.onmicrosoft.com/serverapp/API.Access"
      ],
      "AuthenticationExtraScopes": [],
      "AndroidBrowserTabActivity": {
        "scheme": "msal6f1bf067-d09a-4e31-9590-6818d812919e",
        "host": "auth"
      },
      "LoginHintType": "None"
    },
    "auth_msal": {
      "AuthenticationRedirectUrl": "",
      "AuthenticationAppClientId": "",
      "AuthenticationDomain": "",
      "AuthenticationTenantId": "",
      "AuthenticationUseEmbeddedWebView": true,
      "IOSKeyChainGroup": "",
      "Scopes": [],
      "AuthenticationExtraScopes": [],
      "AndroidBrowserTabActivity": {
        "scheme": "",
        "host": "",
        "path": ""
      }
    },
    "ServiceBaseUrlApiVersion": "api/1.0/"
  },
  "name": "Verdis",
  "displayName": "Verdis",
  "style": {
    "colors": {
      "primary": "#091D3E",
      "secondary": "#FF3399",
      "pureBlack": "#000000",
      "textBlack": "#333333",
      "darkGrey50": "#737373",
      "grey20": "#CCCCCC",
      "lightGrey8": "#EBEBEB",
      "lightGrey4": "#999999",
      "lightGrey3": "#F8F8F8",
      "pureWhite": "#FFFFFF",
      "info": "#4BBED2",
      "info25": "#D2EFF4",
      "success": "#34C759",
      "success25": "#CCF1D5",
      "warning": "#FFC832",
      "warning25": "#FFE398",
      "signal": "#FF5400",
      "signal25": "#FFD4BF",
      "danger": "#FF0000",
      "danger25": "#FFBFBF",
      "greyInfoBG": "#6C757D",
      "greyInfoBG25": "#DADDDF",
      "expired": "#FFE7E7",
      "expiringSoon": "#FFFAE5",
      "missing": "#F2F2F2",
      "aggregated": "#E7F1FF",
      "active": "#E8F7E9",
      "unlocked": "#FEE600",
      "lightGrey2": "",
      "grey": "",
      "lightGrey": "",
      "transparentBlack": "#00000080",
      "white": "",
      "color-indicator-active": "#FF3399",
      "color-indicator-inactive": "#FFFFFF",
      "color-text-secondary-temporary": "#FFFFFF"
    },
    "elements": {
      "accent": {
        "color": "#FF3399",
        "textColor": "#FFFFFF"
      },
      "contentLoginScreen": {
        "color": "",
        "textColorPrimary": "",
        "textColorSecondary": ""
      }
    },
    "buttons": {
      "primary": {
        "normal": {
          "color": "#091D3E",
          "textColor": "#FFFFFF",
          "borderThickness": 0,
          "borderColor": "#000000"
        },
        "disabled": {
          "color": "#B0B8C6",
          "textColor": "#D3D3D3",
          "borderThickness": 0,
          "borderColor": "#000000"
        }
      },
      "secondary": {
        "normal": {
          "color": "#FF3399",
          "textColor": "#FFFFFF",
          "borderThickness": 0,
          "borderColor": "#000000"
        },
        "disabled": {
          "color": "#FFC1E0",
          "textColor": "#D3D3D3",
          "borderThickness": 0,
          "borderColor": "#000000"
        }
      },
      "warning": {
        "normal": {
          "color": "#FFC832",
          "textColor": "#000000",
          "borderThickness": 0,
          "borderColor": "#000000"
        },
        "disabled": {
          "color": "#FFF4CC",
          "textColor": "#A0A0A0",
          "borderThickness": 0,
          "borderColor": "#000000"
        }
      },
      "error": {
        "normal": {
          "color": "#FF0000",
          "textColor": "#FFFFFF",
          "borderThickness": 0,
          "borderColor": "#000000"
        },
        "disabled": {
          "color": "#FFA6A6",
          "textColor": "#D3D3D3",
          "borderThickness": 0,
          "borderColor": "#000000"
        }
      },
      "success": {
        "normal": {
          "color": "#34C759",
          "textColor": "#000000",
          "borderThickness": 0,
          "borderColor": "#000000"
        },
        "disabled": {
          "color": "#B8E0C3",
          "textColor": "#A0A0A0",
          "borderThickness": 0,
          "borderColor": "#000000"
        }
      }
    },
    "tabBar": {
      "activeTintColor": "#000000",
      "inactiveTintColor": "#999999",
      "activeTintIconColor": "#FF3399",
      "inactiveTintIconColor": "#999999"
    },
    "headerBar": {
      "backgroundColor": "",
      "textColor": "#ffffff",
      "buttonColor": "#ffffff",
      "buttonInactiveColor": "",
      "loginScreen": {
        "backgroundColor": "",
        "textColor": "",
        "buttonColor": "",
        "buttonInactiveColor": ""
      }
    },
    "fWelcomeScreens": {
      "color-text-primary": "#ffffff",
      "color-background-primary": "neutral-50",
      "color-foreground-primary": "#FEE600",
      "color-text-secondary": "#ffffff",
      "color-background-secondary": "neutral-50",
      "color-foreground-secondary": "neutral-50",
      "color-action-primary": "primary-600",
      "color-indicator-active": "primary-500",
      "color-indicator-inactive": "neutral-300"
    },
    "features": {
      "loginScreen": {
        "backgroundImage": ""
      },
      "loginHelpScreen": {
        "backgroundImage": ""
      },
      "initializationScreen": {
        "backgroundImage": ""
      }
    }
  }
}, r('AppConfig'));